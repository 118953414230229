<template>
  <div class="top-bar text-white d-flex justify-content-between ">
    <BreadcrumbView style="margin-top: 6px;" />

    <div class="connect-button">
      <button
        v-if="!connected"
        class="btn btn-primary"
        @click="connectWallet"
      >
        Connect
      </button>
      <div
        v-else
        class="dropdown"
      >
        <button
          id="dropdownMenuButton"
          class="btn btn-warning dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img
            src="../assets/class-users-default.png"
            style="padding: 0px; width: auto; height: 36px; margin-right: 6px;"
            alt="Sample Image"
          >
          {{ shortenedWalletAddress + " " }}
        </button>
        <ul
          class="dropdown-menu"
          aria-labelledby="dropdownMenuButton"
        >
          <li>
            <router-link
              to="/inventory"
              class="router-link"
            >
              <a class="dropdown-item">Dashboard</a>
            </router-link>
          </li>
          <li>
            <router-link
              to="/inventory"
              class="router-link"
            >
              <a class="dropdown-item">Inventory</a>
            </router-link>
          </li>
          <li>
            <router-link
              to="/offers"
              class="router-link"
            >
              <a class="dropdown-item">Offers</a>
            </router-link>
          </li>
          <li>
            <router-link
              to="/dojo"
              class="router-link"
            >
              <a class="dropdown-item">Coaching Lab</a>
            </router-link>
          </li>
          <li>
            <router-link
              to="/dojo-simulation"
              class="router-link"
            >
              <a class="dropdown-item">Dojo (Simulator)</a>
            </router-link>
          </li>
          <li>
            <router-link
              to="/grand_dojo"
              class="router-link"
            >
              <a class="dropdown-item">Grand Dojo (Simulator)</a>
            </router-link>
          </li>
          <!-- <li>
            <router-link
              to="/reward"
              class="router-link"
            >
              <a class="dropdown-item">Rewards</a>
            </router-link>
          </li> -->
          <li>
            <router-link
              to="/portal"
              class="router-link"
            >
              <a class="dropdown-item">Portal</a>
            </router-link>
          </li>

          <li>
            <router-link
              to="/account_setting"
              class="router-link"
            >
              <a class="dropdown-item">Account Settings</a>
            </router-link>
          </li>
          <li><hr class="dropdown-divider"></li>
          <li>
            <a
              class="dropdown-item"
              href="#"
              @click="disconnectWallet"
            >Logout</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import Web3 from 'web3';
import { ref, onMounted, computed } from 'vue';
import BreadcrumbView from '@/components/BreadcrumbView.vue';
import Cookies from 'js-cookie';
import { useStore } from '@/stores/index.ts';

const connected = ref(false);
const walletAddress = ref('');
const store = useStore();

const isConnected = Cookies.get('connected') === 'true';
console.log("isConnected" + isConnected);

const apiKey = process.env.VITE_JAB_CONTRACT_ADDRESS;
console.log(apiKey);

const jabContractAbi = [
    {
        constant: true,
        inputs: [{ name: '_owner', type: 'address' }],
        name: 'balanceOf',
        outputs: [{ name: 'balance', type: 'uint256' }],
        type: 'function',
    },
];

onMounted(async () => {
  // Check wallet connection when the component is mounted
  if (isConnected) {
    await checkWalletConnection(false);
  }
  
});

const connectWallet = async () => {
  await checkWalletConnection();
};

const checkWalletConnection = async (isShowError = true) => {
  try {
    if (typeof window.ethereum !== 'undefined') {
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      const networkId = await window.ethereum.request({ method: 'net_version' });
      console.log("networkId" ,networkId);
      console.log("networkId" ,process.env.VITE_NETWORK_ID);

      if (networkId === process.env.VITE_NETWORK_ID || networkId === '80001') {
        const web3 = new Web3(window.ethereum);
        const accounts = await web3.eth.getAccounts();
        connected.value = true;
        walletAddress.value = accounts[0];
        store.setWalletAddress(walletAddress.value);
        Cookies.set('connected', connected.value);

        const jabContractAddress = process.env.VITE_JAB_CONTRACT_ADDRESS;
        console.log("jabContractAddress" +jabContractAddress);
        const jabTokenContract = new web3.eth.Contract(jabContractAbi, jabContractAddress);
        const jabBalanceWei = await jabTokenContract.methods.balanceOf(accounts[0]).call();
        const jabBalanceWith2DecimalPlaces = parseFloat(web3.utils.fromWei(jabBalanceWei, 'ether')).toFixed(0);

        store.setWalletBalance( {  jab: jabBalanceWith2DecimalPlaces , kf: 0});
        //console.log("Jab Balance" + jabBalanceWith2DecimalPlaces);
      }else {
        console.error('Please switch to the Kroma Sepolia network in MetaMask');
        alert('Please switch to the Kroma Sepolia network in MetaMask');
        return 0;
      }
    } else {
      Cookies.set('connected', false);
      if (isShowError) alert('Please install MetaMask to use this feature.');
    }
  } catch (error) {
    console.error(error);
    Cookies.set('connected', false);
    if (isShowError) alert('Failed to connect to MetaMask. Please try again.');
  }
};

const disconnectWallet = async () => {
  try {
    if (window.ethereum && window.ethereum.isConnected()) {
      await window.ethereum.request({ method: 'eth_requestAccounts', params: [] });
    }
    connected.value = false;
    walletAddress.value = '';
    Cookies.set('connected', connected.value);
    console.error('Wallet has been disconnected');
  } catch (error) {
    console.error(error);
    alert('Failed to disconnect from MetaMask. Please try again.');
  }
};

const shortenedWalletAddress = computed(() => {
  if (walletAddress.value) {
    return `${walletAddress.value.slice(0, 6)}...${walletAddress.value.slice(-3)}`;
  }
  return '';
});
</script>



<style scoped>
.top-bar {
  position: fixed;
  background-color: var(--dark-background-color);
  height: max-content;
  padding-left: 36px !important;
  padding: 12px;
  top: 0;
  width: calc(100% - 260px); 
  z-index: 2;
  margin-left: 246px;
}


.top-bar::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px; /* Set the height of your gradient line */
  background: linear-gradient(to right, #14121f, #4d4e59,#14121f); /* Gradient colors */
}

.connect-button {
  padding-right: 60px; /* Add padding to the right side of the Connect button */
}

.dropdown-toggle {
    background-color: #171527; /* Set background color */
    border-color: #ff65ed; /* Set border color */
    color: #ffffff; /* Set text color */
}

.dropdown-toggle:hover {
    background-color: #fa65ff55; /* Set hover background color */
    border-color: #f565ff; /* Set hover border color */
    color: #ffffff; /* Set hover text color */
}

.router-link{
  text-decoration: none; 
}


@media (max-width: 768px) {
  .top-bar {
    width: 100% !important; /* Set the width to 100% minus the width of the left menu */
    padding-left: 80px !important;
    margin-left: 0px !important;
  }

  .connect-button {
    padding-right: 20px; /* Add padding to the right side of the Connect button */
  }
}

</style>
