<template>
  <div
    id="craftPopup"
    class="modal fade no-padding"
    tabindex="-1"
    aria-labelledby="craftPopup"
    aria-hidden="true"
    @click.self="selectButton">
    <div class="modal-dialog modal-dialog-centered" style="--bs-modal-width: 600px;">
      <div class="modal-content" style="background-color: #262252;">
        <div class="modal-header">
          <h5
            id="exampleModalLabel"
            class="modal-title"
          >
            Craft
          </h5>
          <button
            ref="Close"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="selectButton"
          />
        </div>
        <div class="modal-body" style="padding: 0;">
          <div class="" style="background-color: #101327; height: 70px; width: 100%; display: inline-flex; align-items: center; justify-content: center; font-weight: 600; color: #df4bd3;">
            Craft your material for a fragment
          </div>
          <div style="padding: 10px 20px; display: flex; align-items: center; justify-content: center; flex-direction: column;">
            <div style="font-weight: 600;">
              recipes
            </div>
            <div style="display: flex; justify-content: center; align-items: center; width: 100%; margin: 10px; overflow-x: auto; gap: 15px; flex-wrap: wrap;">
              <template v-for="recp in recipesList">
                <label
                  v-if="recp.id > 0"
                  :key="recp.id"
                  class="btn btn-secondary dropdown-filter-toggle"
                  :class="{ active: isSelectedRecipe(recp.id) }"
                  style="padding: 5px 10px; cursor: pointer; display: inline-flex; align-items: center;"
                >
                  <input
                    type="radio"
                    class="btn-check"
                    v-model="selectedRecipe"
                    @change="selectRecipe(recp)"
                    style="display: none;"
                  />
                  <img width="30" :src="`../images/item/${recp.code}.png`" alt="Element icon" style="padding-right: 5px;" />
                  <span class="content-text">{{ recp.name }}</span>
                </label>
              </template>
            </div>  
            <div class="d-flex justify-content-center align-items-center" style="margin: 0 30px; background-color: #101327; width: 100%; border-radius: 8px; flex-direction: column;">
              <div style="color: #B6B6BB; margin: 5px; font-weight: 500;">material</div>
              <div class="d-flex justify-content-center align-items-center" style="gap: 20px;">
                <div class="d-flex justify-content-center align-items-center" style="flex-direction: column;">
                  <img class="material-icon" :src="`../images/item/${getMaterialUse()?.code || ''}.png`" alt="material 1 icon" style="" />
                  <div class="detail-text width-detail-text">
                    <span id="amountId">{{ getMatetialAmount(getMaterialUse()?.code?? '') }}</span>/{{ materialUseTotal }}
                  </div>
                </div>
                <img width="20" :src="`../images/arrow-icon.png`" alt="icon" style="" />
                <div class="d-flex justify-content-center align-items-center" style="flex-direction: column;">
                  <img class="material-icon" :src="`../images/item/${selectedRecipe.code || ''}.png`" alt="material 1 icon" style="" />
                  <div id="resultAmountId" class="detail-text width-detail-text">
                    {{ craftAmount }}
                  </div>
                </div>
              </div>
              <div style="color: #FFD600; margin: 5px; font-weight: 600; padding: 10px;">success rate {{ getMaterialUse()?.rate?? 0 }}%</div>
            </div>
            <div class="d-flex align-items-end" style="width: 100%; margin: 10px 0; height: 40px; justify-content: end;">
              <!-- <div class="detail-text" style="background-color: black; width: 100px; height: 80%;"> total: </div> -->
              <div class="d-flex justify-content-end align-items-end" style="height: 100%; gap: 10px;">
                <div class="d-flex justify-content-center align-items-center" style="width: auto; height: 80%;">
                  <button 
                    :class="['amount-content', 'btn-decrease', { 'btn-disabled': isDecreaseDisable }]" 
                    :disabled="isDecreaseDisable" 
                    @click="updateCraftAmount(false)" 
                    style="border-top-left-radius: 8px; border-bottom-left-radius: 8px;"> - 
                  </button>
                  <span class="amount-content d-flex justify-content-center align-items-center" style=""> {{ craftAmount }} </span>
                  <button 
                    :class="['amount-content', 'btn-increase', { 'btn-disabled': isIncreaseDisable }]" 
                    :disabled="isIncreaseDisable" 
                    @click="updateCraftAmount(true)" 
                    style="border-top-right-radius: 8px; border-bottom-right-radius: 8px;"> + 
                  </button>
                </div>
                <button class="detail-text" @click="getMaxCraft()" style="background-color: black; width: 50px; height: 80%; padding: 0 10px;"> max </button>
                <button :class="['btn', 'btn-primary', { 'btn-disabled': isCraftDisable }]" :disabled="isCraftDisable" style="background-color: black; padding: 0 20px; height: 100%;"> cratf </button>
              </div>
            </div>
          </div>
        </div>  
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from 'axios';
import Web3 from 'web3';
import { useStore } from '@/stores/index.ts';
import { getSignature } from '@/utils/web3Utils';
import NFTContractABI from '@/contracts/NFTContractABI.json';

import {useToast} from 'vue-toast-notification';
import { ref, onMounted, watch } from 'vue';


export default {
    components: {
    },
    props: {
        showModal: Boolean,
        totalFragment: {
            type: Object,
            required: true
        }
        // selectedDefault: {
        //     type: Number,
        //     required: true,
        //     default: () => 0
        // }
    },
    data() {
        return {
          recipesList: [{id:0, code:'ITM_FM_MCC', name:'Microship', material_use:5, rate:100}, 
                        {id:1, code:'ITM_FM_GLB', name:'Gold Bar', material_use:2, rate:80}, 
                        {id:2, code:'ITM_FM_HLB', name:'Holo Bar', material_use:2, rate:70}, 
                        {id:3, code:'ITM_FM_FST', name:'Suit'}],
          selectedRecipe: {},
          craftAmount: 1,
          materialUseTotal: 0,
          isDecreaseDisable: false,
          isIncreaseDisable: false,
          isCraftDisable: false
        };
    },
    // watch: {
    //   selectedDefault: {
    //     handler(newVal, oldVal) {
    //       console.log('selectedDefault :', newVal);
    //       this.selectedRecipe = this.recipesList.find(f=>f.id == newVal);
    //     },
    //     immediate: true
    //   }
    // },
    mounted() {
      // this.selectedRecipe = this.recipesList.find(f=>f.id == 1);
      console.log(this.totalFragment);
      this.selectRecipe(this.recipesList.find(f=>f.id == 1));
    },
    methods: {
        closeModal(){
          console.log('closeModal');
          // this.$refs.Close.click();
        },
        selectButton() {
            console.log('Closing modal'); // Add this line
            this.$emit('selectButton', 0);
            this.closeModal();
        },
        isSelectedRecipe(id){
          // console.log(this.selectedRecipe);
          return this.selectedRecipe ? (this.selectedRecipe.id === id) : false;
        },
        selectRecipe(recp){
          this.selectedRecipe = recp;

          this.craftAmount = 1;
          this.materialUseTotal = this.getMaterialUse()?.material_use?? 0;
          this.updateButton();
        },
        updateCraftAmount(isIncrease){
          if (isIncrease) {
            this.craftAmount++; // Increment
          } else {
            this.craftAmount--; // Decrement
          }

          this.materialUseTotal = (this.getMaterialUse()?.material_use?? 0)*this.craftAmount;
          
          this.updateButton();
        },
        updateButton(){
          if(this.craftAmount == 1){
            this.isDecreaseDisable = true;
          }
          else{
            this.isDecreaseDisable = false;
          }

          const hasTotal = this.getMatetialAmount(this.getMaterialUse()?.code?? '');

          const amountIdElement = document.getElementById('amountId');
          if(hasTotal < this.materialUseTotal){
            this.isCraftDisable = true;
            amountIdElement.style.color = 'red';
          }
          else{
            this.isCraftDisable = false;
            amountIdElement.style.color = 'lightgreen';
          }
        },
        getMaterialUse(){
          return this.recipesList.find(f=>f.id == ((this.selectedRecipe?.id?? 0)-1));
        },
        getMatetialAmount(code){
          return this.totalFragment?.find(f=>f.code == code)?.amount?? 0;
        },
        getMaxCraft(){
          const materialUse = this.getMaterialUse()?.material_use?? 0;
          const maxResult = Math.floor(this.getMatetialAmount(this.getMaterialUse()?.code?? '') / materialUse); 

          this.craftAmount = maxResult;
          this.materialUseTotal = materialUse*this.craftAmount;
          this.updateButton();
        }
    }
};
</script>

<style scoped lang="scss">
  .no-padding {
    padding: 0 !important;
  }

  .modal-header {
    border-bottom: none;
  }
  
  .dropdown-filter-toggle {
    background-color: #101327;
  }

  .modal-title {
    text-align: center;
    width: 100%;
    font-weight: 600;
  }

  .dropdown-filter-toggle.active {
    background-color: #ff65fc55;
    border-color: #ff65fa;
    color: white;
  }

  .detail-text {
    color: white;
    display: inline-flex;
    align-items: center;
    background-color: var(--light-background-color);
    border-radius: 8px;
    justify-content: center;
    font-size: 14px;
    line-height: 20px;
    width: 60px;
    font-weight: 500;
  }

  .width-detail-text{
    width: 100px;
  }

  .material-icon {
    width: 100px;
  }

  .btn-decrease {
    background-color: black;
  }

  .btn-increase {
    background-color: black;
  }

  .btn-decrease:hover:not(.btn-disabled){
    background-color: darkred;
  }

  .btn-increase:hover:not(.btn-disabled) {
    background-color: darkgreen;
  }

  .btn-disabled{
    background-color: #e0e0e0; /* Grey */
    color: darkgray; /* Light grey */
    cursor: not-allowed;
    opacity: 0.6;
  }

  .amount-content {
    width: 40px; 
    height: 100%; 
    background-color: black;
    color: white;
  }

@media (max-width: 768px) {
    .modal-title {
        font-size: 120%;
    }
    
    .modal-body {
        padding: 0.5rem 0;
    }

    .material-icon {
      width: 70px;
    }
}
</style>
  
