<template>
    <div class="page-content content-wrap">
      <div class="vertical-opacity-overlay" />
      <div class="main-content">
        <div class="d-flex align-items-left">
          <h1>Fighter Workshop</h1>
        </div>
        <div class="menu-content">
            <button 
                class="btn btn-menu" 
                :class="{ 'btn-menu-selected': isCraftSelected }" 
                type="button" 
                id="craftBtn" 
                data-bs-toggle="modal" 
                data-bs-target="#craftPopup" 
                @click="selectButton(0)"
                style="">
                <img class="icon-image" :src="`../images/craft-icon.png`" alt="craft-icon" style="" />
                <span> Craft </span>
            </button>
            <button 
                class="btn btn-menu" 
                :class="{ 'btn-menu-selected': isFittingSelected }" 
                type="button" 
                id="fittingBtn" 
                data-bs-toggle="modal" 
                data-bs-target="#craftPopup" 
                @click="selectButton(1)"
                style="">
                <img class="icon-image" :src="`../images/fitting-icon.png`" alt="fitting-icon" style="" />
                <span> Fitting Room </span>
            </button>
        </div>
      </div>
    </div>
    <CraftPopup :totalFragment="totalFragment" @selectButton="selectButton"/>
</template>
    
<script>
import { useStore } from '@/stores/index.ts';
import CraftPopup from "@/components/popup/CraftPopup.vue";

export default {
    components: {
        CraftPopup
    }, data() {
        return {
            isCraftSelected : false,
            isFittingSelected: false,
            totalFragment: [{code:'ITM_FM_MCC', amount:10},
                            {code:'ITM_FM_GLB', amount:10},
                            {code:'ITM_FM_HLB', amount:10},
                            {code:'ITM_FM_FST', amount:10}]
        };
    },
    mounted() {
        // if(useStore().getWalletAddress!=''){
        //     this.fetchData();
        // }else{
        //     watch(() => useStore().getWalletAddress, () => {
        //         this.fetchData();
        //     });
        // }
    },
    methods: {
        selectButton(index){
            if(index == 0){
                this.isCraftSelected = !this.isCraftSelected;
            }
            else if(index == 0){
                this.isFittingSelected = !this.isFittingSelected;
            }
        },
        async fetchData() {
            try {
                const fragmentCollection = process.env.VITE_FRAGMENT_CONTRACT_ADDRESS;
                const fragmentRequestBody = {
                    size: 50,
                    filter: {
                        blockchains: ['KROMA'],
                        owners: [`ETHEREUM:${accountAddress}`],
                        collections: [`KROMA:${fragmentCollection}`]
                    }
                };
                const fragmentApiUrl = `https://testnet-api.rarible.org/v0.1/ownerships/search`;
                const fragmentResponse = await axios.post(fragmentApiUrl, fragmentRequestBody, {
                    headers: {
                        'accept': 'application/json',
                        'x-api-key': process.env.VITE_RARIBLE_API_KEY
                    }
                });
                console.log("fragmentResponse ",fragmentResponse);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    }
}
</script>
  
<style scoped>
    .page-content {
        background-image: url('../images/workshop-bg.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .menu-content{
        width: 100%; 
        display: inline-flex; 
        justify-content: center; 
        align-items: center; 
        margin-top: 60px; 
        margin-bottom: 30px; 
        gap: 10%
    }

    .btn-menu{
        width: 220px; 
        height: 330px; 
        transform: skew(-10deg);
        font-size: 30px;
        font-weight: 600;
        background-color: #2c2a3d;
        border: 3px solid #24203a;
        display: flex; 
        flex-direction: column; 
        justify-content: center; 
        align-items: center; 
        gap: 10px;
    }

    .btn-menu:hover{
        border: 3px solid lightgoldenrodyellow;
        background: linear-gradient(135deg, #f526b6, #920bf9);
    }

    .btn-menu-selected{
        border: 3px solid lightgoldenrodyellow;
        background: linear-gradient(135deg, #f526b6, #920bf9);
    }

    .icon-image {
        width: 100px;
    }

@media (max-width: 768px) {
    .btn-menu{
        width: 132px; 
        height: 198px; 
        transform: skew(-10deg);
        font-size: 20px;
        font-weight: 600;
    }

    .icon-image {
        width: 50px;
    }
}

</style>