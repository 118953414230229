<template>
  <div class="product-recent-list">
    <product-shelves
      title="Recent Listing"
      :products="recentProducts"
    />
  </div>
</template>

<script>
import ProductShelves from '@/components/ProductShelves.vue';
import axios from 'axios';
import { timeAgo } from '@/utils/timeUtils.js';
import { convertToUSD } from '@/utils/exchangeUtils.js';
import { useExchangeStore } from '@/stores/exchangeRate.ts';
import {shortenedWalletAddress_rarible} from '@/utils/walletUtils.js';
import { useStore } from '@/stores/index.ts';

export default {
  components: {
    ProductShelves
  },
  data() {
    return {
      recentProducts: []
    };
  },
  async mounted() {
    try {
      const store = useExchangeStore();
      store.fetchExchangeRates();

      const wallet = useStore().getWalletAddress;

      // const response = await axios.get('https://api.opensea.io/api/v2/listings/collection/kf-genesis-fighter/all?limit=100', {
      //   headers: {
      //     'accept': 'application/json',
      //     'x-api-key': process.env.VITE_OPENSEA_API_KEY
      //   }
      // });
    
      const fighterCollection = process.env.VITE_FIGHTER_CONTRACT_ADDRESS;
      // const fighterApiUrl = `https://testnet-api.rarible.org/v0.1/activities/byCollection?type=LIST&type=SELL&type=CANCEL_LIST&collection=KROMA:${fighterCollection}&size=16`;
      // const fighterResponse = await axios.get(fighterApiUrl, {
      //     headers: {
      //         'accept': 'application/json',
      //         'x-api-key': process.env.VITE_RARIBLE_API_KEY
      //     }
      // });

      const ticketCollection = process.env.VITE_TICKET_CONTRACT_ADDRESS;
      // const ticketApiUrl = `https://testnet-api.rarible.org/v0.1/activities/byCollection?type=LIST&type=SELL&type=CANCEL_LIST&collection=KROMA:${ticketCollection}&size=16`;
      // const ticketResponse = await axios.get(ticketApiUrl, {
      //     headers: {
      //         'accept': 'application/json',
      //         'x-api-key': process.env.VITE_RARIBLE_API_KEY
      //     }
      // });

      const stoneCollection = process.env.VITE_STONE_CONTRACT_ADDRESS;
      // const stoneApiUrl = `https://testnet-api.rarible.org/v0.1/activities/byCollection?type=LIST&type=SELL&type=CANCEL_LIST&collection=KROMA:${stoneCollection}&size=16`;
      // const stoneResponse = await axios.get(stoneApiUrl, {
      //     headers: {
      //         'accept': 'application/json',
      //         'x-api-key': process.env.VITE_RARIBLE_API_KEY
      //     }
      // });
      // console.log("recentSell: stoneResponse " , stoneResponse);

      const artifactCollection = process.env.VITE_ARTIFACT_CONTRACT_ADDRESS;
      // const artifactApiUrl = `https://testnet-api.rarible.org/v0.1/activities/byCollection?type=LIST&type=SELL&type=CANCEL_LIST&collection=KROMA:${artifactCollection}&size=16`;
      // const artifactResponse = await axios.get(artifactApiUrl, {
      //     headers: {
      //         'accept': 'application/json',
      //         'x-api-key': process.env.VITE_RARIBLE_API_KEY
      //     }
      // });
      // console.log("recentSell: artifactResponse " , artifactResponse);

      // const combinedData = [...fighterResponse.data.activities, ...ticketResponse.data.activities, ...stoneResponse.data.activities, ...artifactResponse.data.activities];
      
      const apiUrl = `https://testnet-api.rarible.org/v0.1/activities/byCollection?type=LIST&type=SELL&type=CANCEL_LIST&collection=KROMA:${fighterCollection}&collection=KROMA:${ticketCollection}&size=32`;
      const response = await axios.get(apiUrl, {
          headers: {
              'accept': 'application/json',
              'x-api-key': process.env.VITE_RARIBLE_API_KEY
          }
      });
      const combinedData = [...response.data.activities];

      const recentSell = combinedData.filter(activity => activity['@type'] == 'SELL');
      const recentList = combinedData.filter(activity => activity['@type'] == 'LIST');
      const cancelList = combinedData.filter(activity => activity['@type'] == 'CANCEL_LIST');

      console.log("recentSell: " , recentSell);

      console.log("recentList: " , recentList);

      const sellMap = new Map();
      recentSell.forEach(sell => {
        const tokenId = sell.nft.type.tokenId;
        const lastUpdatedAt = new Date(sell.lastUpdatedAt);
        if (!sellMap.has(tokenId) || new Date(sellMap.get(tokenId).lastUpdatedAt) < lastUpdatedAt) {
          sellMap.set(tokenId, sell);
        }
      });

      const cancelMap = new Map();
      cancelList.forEach(sell => {
        const tokenId = sell.make.tokenId;
        const lastUpdatedAt = new Date(sell.lastUpdatedAt);
        if (!cancelMap.has(tokenId) || new Date(cancelMap.get(tokenId).lastUpdatedAt) < lastUpdatedAt) {
          cancelMap.set(tokenId, sell);
        }
      });

      // Remove items in recentList that are also in recentSell
      const filteredList = recentList.filter(list => {
        const tokenId = list.make.type.tokenId;
        const lastUpdatedAt = new Date(list.lastUpdatedAt);
        const sellItem = sellMap.get(tokenId);
        const cancelItem = cancelMap.get(list.hash);

        return (!sellItem  || lastUpdatedAt > new Date(sellItem.lastUpdatedAt)) && (!cancelItem || lastUpdatedAt > new Date(cancelItem.lastUpdatedAt) );
      });

      console.log("filteredList: " , filteredList);

      const ethToUsdExchangeRate = store.getEthToUsdExchangeRate;
      const maticToUsdExchangeRate = store.getMaticToUsdExchangeRate;

      filteredList.sort((a, b) => {  
          const date1 = new Date(b.lastUpdatedAt);
          const date2 = new Date(a.lastUpdatedAt);
          return date1 - date2;
      });

      console.log("response recentProducts" , filteredList);
      
      // Extract relevant data from the API response and update recentProducts
      // this.recentProducts = filteredList.map(listing => {
      //   const date = new Date(listing.lastUpdatedAt);
      //   const unixTimestamp = Math.floor(date.getTime() / 1000);
      //   const tokenID = listing.make.type.tokenId;
      //   const dateString = timeAgo(unixTimestamp);
      //   const price = parseFloat(listing.price);
      //   const usdPrice = convertToUSD(price, "JAB", ethToUsdExchangeRate, maticToUsdExchangeRate);
      //   //console.log("usdPrice" + usdPrice);
      //   return {
      //     id: tokenID,
      //     price: price,
      //     currency: "JAB",
      //     listDate: listing.lastUpdatedAt,
      //     dateString : dateString,
      //     usdPrice : usdPrice,
      //     isOwner: false
      //   };
      // });

      // const itemApiUrl = `https://testnet-api.rarible.org/v0.1/items/byIds`;

      // const itemHeaders = {
      //   'accept': 'application/json',
      //   'content-type': 'application/json',
      //   'X-API-KEY': process.env.VITE_RARIBLE_API_KEY,
      // };

      // const itemRequestBody = {
      //   ids: filteredList.map(nft => `${nft.make.type.collection}:${nft.make.type.tokenId}`)
      // };
      // console.log("itemRequestBody:", itemRequestBody);

      // const itemResponse = await axios.post(itemApiUrl, itemRequestBody, { headers: itemHeaders });
      // console.log("itemResponse:", itemResponse);

      const latestMap = new Map();
      filteredList.forEach(item => {
        if (!latestMap.has(item.make.type.tokenId)) {
          latestMap.set(item.make.type.tokenId, item);
        } else {
          const existingItem = latestMap.get(item.make.type.tokenId);
          if (new Date(item.lastUpdatedAt) > new Date(existingItem.lastUpdatedAt)) {
          // if (Number(item.price) < Number(existingItem.price)) {
            latestMap.set(item.make.type.tokenId, item);
          }
        }
      });
      const uniqueList = Array.from(latestMap.values());
      console.log("itemResponse:", uniqueList);
      
      const recentProducts1 = uniqueList.map(listing => {
        const date = new Date(listing.lastUpdatedAt);
        const unixTimestamp = Math.floor(date.getTime() / 1000);
        const tokenID = listing.make.type.tokenId;
        const dateString = timeAgo(unixTimestamp);
        const price = parseFloat(listing.price);
        const usdPrice = convertToUSD(price, "JAB", ethToUsdExchangeRate, maticToUsdExchangeRate);
        const collection = listing.make.type.collection.slice(6).toLowerCase();
        const isFighterProduct = (collection == fighterCollection.toLowerCase());
        var itemID = 0;

        if(!isFighterProduct){
          switch(collection){
            case process.env.VITE_TICKET_CONTRACT_ADDRESS.toLowerCase(): itemID = 100; break;
            case process.env.VITE_STONE_CONTRACT_ADDRESS.toLowerCase():
            case process.env.VITE_ARTIFACT_CONTRACT_ADDRESS.toLowerCase(): itemID = tokenID; break;
          }
        }

        console.log(collection+':'+itemID);

        return {
          id: tokenID,
          price: price,
          currency: "JAB",
          listDate: listing.lastUpdatedAt,
          dateString : dateString,
          usdPrice : 0,
          isFighter: isFighterProduct,
          itemId: itemID,
          image: '',
          type: '',
          description: '',
          isOwner: shortenedWalletAddress_rarible(listing.maker) == wallet,
        };
      });

      const stoneApiUrl = `https://testnet-api.rarible.org/v0.1/items/byCollection?collection=KROMA:${stoneCollection}&size=5`;
      const stoneResponse = await axios.get(stoneApiUrl, {
          headers: {
              'accept': 'application/json',
              'x-api-key': process.env.VITE_RARIBLE_API_KEY
          }
      });
      const artifactApiUrlApiUrl = `https://testnet-api.rarible.org/v0.1/items/byCollection?collection=KROMA:${artifactCollection}&size=5`;
      const artifactResponse = await axios.get(artifactApiUrlApiUrl, {
          headers: {
              'accept': 'application/json',
              'x-api-key': process.env.VITE_RARIBLE_API_KEY
          }
      });

      const combinedEquipment = [...stoneResponse.data.items, ...artifactResponse.data.items];
      const recentProducts2 = combinedEquipment.filter(f=>f.bestSellOrder && f.bestSellOrder !== null && f.bestSellOrder !== undefined).map(listing => {
        const date = new Date(listing.lastUpdatedAt);
        const unixTimestamp = Math.floor(date.getTime() / 1000);
        const tokenID = listing.tokenId;
        const dateString = timeAgo(unixTimestamp);
        const price = parseFloat(listing.bestSellOrder.makePrice);
        const usdPrice = convertToUSD(price, "JAB", ethToUsdExchangeRate, maticToUsdExchangeRate);

        return {
          id: tokenID,
          price: price,
          currency: "JAB",
          listDate: listing.lastUpdatedAt,
          dateString : dateString,
          usdPrice : 0,
          isFighter: false,
          itemId: tokenID,
          image: '',
          type: '',
          description: '',
          isOwner: shortenedWalletAddress_rarible(listing.bestSellOrder.maker) == wallet,
        };
      })

      this.recentProducts = [...recentProducts1, ...recentProducts2];
      this.recentProducts.sort((a, b) => {
        const dateA = new Date(a.listDate);
        const dateB = new Date(b.listDate);

        return dateB - dateA;
      });

    } catch (error) {
      console.error('Error fetching data from OpenSea:', error);
    }
  }
};
</script>

<style scoped>
/* Styles for the ParentComponent */
</style>