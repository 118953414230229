<template>
  <div class="page-content content-wrap">
    <div class="vertical-opacity-overlay" />
    <div class="main-content">
      <div class="d-flex align-items-left">
        <img
          src="../assets/Icon-stats-owners.png"
          alt="NFT Icon"
          class="header-icon mr-3"
          style="width:50px;"
        >
        <h1>Inventory</h1>
      </div>

      <div style="margin-top:20px; ">
        <div class="row default-row">
          <div class="col-md-4">
            <div class="card">
              <div class="card-body">
                <div class="stat-section stats-logo d-flex align-items-center">
                  <img
                    src="../assets/icons/icon-kf.png"
                    alt="NFT Icon"
                    class="stat-icon"
                  >
                  <div class="stat-content">
                    <h5 class="stat-value">
                      {{ wallets.kfBalance }} KF
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card">
              <div class="card-body">
                <div class="stat-section stats-logo">
                  <img
                    src="../assets/icons/icon-jab.png"
                    alt="Owner Icon"
                    class="stat-icon"
                  >
                  <div class="stat-content">
                    <h5 class="stat-value">
                      {{ wallets.jabBalance }} JAB
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card">
              <div class="card-body">
                <div class="stat-section stats-logo ">
                  <img
                    src="../assets/icons/icon-kroma.png"
                    alt="Volume Icon"
                    class="stat-icon"
                  >
                  <div class="stat-content">
                    <h5 class="stat-value">
                      {{ wallets.maticBalance }} KRO
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a
              class="nav-link"
              :class="{ active: activeTab === 'fighters' }"
              @click="activateTab('fighters')"
            >
              Fighters {{ formattedFrameAmount(amounts["fighter"]) }}
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              :class="{ active: activeTab === 'ticket' }"
              @click="activateTab('ticket')"
            >
              Ticket {{ formattedFrameAmount(amounts["ticket"]) }}
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              :class="{ active: activeTab === 'equipment' }"
              @click="activateTab('equipment')"
            >
              Stone / Artifact {{ formattedFrameAmount(amounts["equipment"]) }}
            </a>
          </li>
          <!-- <li class="nav-item">
                        <a class="nav-link" :class="{ active: activeTab === 'frame' }" @click="activateTab('frame')">
                            Frame {{ formattedFrameAmount(amounts["frame"]) }}
                        </a>
                    </li> -->
          <li class="nav-item">
            <a
              class="nav-link"
              :class="{ active: activeTab === 'misc' }"
              @click="activateTab('misc')"
            >
              Misc {{ formattedFrameAmount(amounts["misc"]) }}
            </a>
          </li>
        </ul>

        <!-- Tab content -->
        <div
          class="tab-content"
          style="margin-top: 10px;"
        >
          <div
            class="tab-pane"
            :class="{ active: activeTab === 'fighters' }"
          >
            <FightersTab :amounts="amounts" />
          </div>
          <div
            class="tab-pane"
            :class="{ active: activeTab === 'ticket' }"
          >
            <TicketTab :amounts="amounts" />
          </div>
          <div
            class="tab-pane"
            :class="{ active: activeTab === 'equipment' }"
          >
            <EquipmentTab :amounts="amounts" />
          </div>
          <div
            class="tab-pane"
            :class="{ active: activeTab === 'frame' }"
          >
            <FrameTab :amounts="amounts" />
          </div>
          <div
            class="tab-pane"
            :class="{ active: activeTab === 'misc' }"
          >
            <MiscTab :amounts="amounts" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>

import Web3 from 'web3';
import ProductItem from '@/components/ProductFighterItem.vue'; // Update the path based on your project structure
import { timeAgo, convertToTimestamp } from '@/utils/timeUtils.js';
import { convertToUSD } from '@/utils/exchangeUtils.js';
import { useExchangeStore } from '@/stores/exchangeRate.ts';
import { useStore } from '@/stores/index.ts';

import FightersTab from '@/components/inventory/FightersTab.vue';
import TicketTab from '@/components/inventory/TicketTab.vue';
import EquipmentTab from '@/components/inventory/EquipmentTab.vue';
import FrameTab from '@/components/inventory/FrameTab.vue';
import MiscTab from '@/components/inventory/MiscTab.vue';

import { ref, onMounted, watch } from 'vue';
import axios from 'axios';

export default {
    components: {
        ProductItem,
        FightersTab,
        FrameTab,
        TicketTab,
        EquipmentTab,
        MiscTab
    }, data() {
        return {
            products: [],
            amounts: {},

            wallets: {
                maticBalance: 0,
                kfBalance: 0,
                jabBalance: 0,
            },
            activeTab: 'fighters',
        };
    },
    mounted() {

        this.fetchWalletBalance().then(() => {
            //this.fetchFighterData();
        });

        watch(
            () => this.selectedClasses,
            () => {
                // Fetch image URL when the component is mounted
                this.fetchFighterData();
            }
        );
    },
    methods: {
        formattedFrameAmount(amount) {
            if (amount > 0) {
                return `(${amount})`;
            } else {
                return "";
            }
        },
        activateTab(tabName) {
            this.activeTab = tabName;
        },
        async fetchWalletBalance() {


            // Check if MetaMask is installed
            if (typeof window.ethereum === 'undefined') {
                console.error('MetaMask is not installed');
                return;
            }

            const jabContractAddress = process.env.VITE_JAB_CONTRACT_ADDRESS;
            const jabContractAbi = [
                {
                    constant: true,
                    inputs: [{ name: '_owner', type: 'address' }],
                    name: 'balanceOf',
                    outputs: [{ name: 'balance', type: 'uint256' }],
                    type: 'function',
                },
            ];


            try {
                // Request access to the user's MetaMask wallet
                await window.ethereum.request({ method: 'eth_requestAccounts' });

                // Get the selected network from MetaMask (Ethereum or Matic)
                const networkId = await window.ethereum.request({ method: 'net_version' });
                
                //console.error('process.env.VITE_NETWORK_ID', process.env.VITE_NETWORK_ID);
                
                // Check if the network is Matic
                if (networkId === process.env.VITE_NETWORK_ID ||networkId === '80001') {
                    // Get the current account address from MetaMask
                    const accounts = await window.ethereum.request({ method: 'eth_accounts' });
                    const account = accounts[0];

                    const web3 = new Web3(window.ethereum);

                    const balanceWei = await web3.eth.getBalance(account);
                    const balanceEther = web3.utils.fromWei(balanceWei, 'ether');

                    console.log(`Matic Balance for ${account}: ${balanceEther} MATIC`);
                    this.wallets.maticBalance = parseFloat(balanceEther).toFixed(2);

                    const jabTokenContract = new web3.eth.Contract(jabContractAbi, jabContractAddress);
                    const jabBalanceWei = await jabTokenContract.methods.balanceOf(account).call();
                    const jabBalanceWith2DecimalPlaces = parseFloat(web3.utils.fromWei(jabBalanceWei, 'ether')).toFixed(0);

                    this.wallets.jabBalance = jabBalanceWith2DecimalPlaces;

                    return balanceEther;
                } else {
                    console.error('Please switch to the Matic network in MetaMask');
                    return 0;
                }
            } catch (error) {
                console.error('Error fetching Matic balance:', error);
                return 0;
            }
        }
    }
}
</script>
  
  
<style scoped>
/* Add your component-specific styles here */

.nav-tabs {
    color: gray;
    border: 0;
    border-bottom: 1px solid var(--primary-border-color);
}

.nav-tabs .nav-link.active {
    background-color: var(--soft-background-color);
}

.nav-tabs .nav-link.inactive {
    background-color: var(--soft-background-color);
}


/* Change the background color of inactive tabs */
.nav-tabs .nav-link :hover {
    border-color: var(--primary-border-color);

}
</style>