<template>
  <ListForSalePopup
    :product="item"
    :type="'item'"
  />
  <TransferPopup
    :product="item"
    :type="'item'"
  />
  <ConfirmPurchasePopup
    :product="item"
    :type="'item'"
  />
  <RecieveItemPopup
    :show-modal="isRecieved"
    :item="recievedItem"
  />
  <OpeningItemPopup
    :show-modal="isOpening"
    :item="item"
    :is-confirmed="isConfirmed"
  />
  <SuccessPopup :type="'open'"></SuccessPopup>
  <OfferPopup :product="item" :type="'item'" @fetchEvent="fetchEvent" />
  <div class="page-content content-wrap">
    <div class="vertical-opacity-overlay" />
    <div class="main-content">
      <div
        class=""
        style="max-width: 1032px;"
      >
        <h1 class="heading">
          #{{ tokenId }} {{ item.name }}
        </h1>
        <div>
          <div
            class="left-container"
            style="width: 60%; display: inline-block; vertical-align: top; padding-top: 120px; top: 0;"
          >
            <div
              v-if="isLoading"
              style="margin: 50px; margin-top: 0px;"
            />
            <div v-else>
              <div
                class="item-placeholder"
                style="margin-bottom: 20px;"
              >
                <img
                  v-if="item.itemId == 4 && item.amount<0 "
                  class="item-image"
                  :src="`../images/item/disable/ITM_LT_BOX.png`"
                  alt="Item image"
                >
                <img
                  v-else
                  class="item-image"
                  :src="item.image"
                  alt="Item image"
                  id="item-image"
                >
              </div>

              <div style="margin-bottom: 80px;">
                <div v-if="item.amount">
                  <h4
                    class="secondary-text"
                    style="text-align: center;"
                  >
                    Quantity: {{ item.amount }}
                  </h4>
                </div>
              </div>

              <!-- <div v-if="isOwner"> -->
                <div v-if="isOwner == true && isOpenable == true && isItemListed == false">
                  <div
                    class=""
                    style="width: 100%; margin-bottom: 100px; display: inline-flex; justify-content: center;"
                  >
                    <button
                      type="submit"
                      class="btn btn-primary"
                      :disabled="isLoading"
                      style="width: 160px;"
                      @click="useItemClick"
                    >
                      <span v-if="isOpening"><i class="fa fa-circle-o-notch fa-spin" /></span>
                      <span v-else>Open</span>
                    </button>
                  </div>
                </div>
                <div v-else>
                  <div v-if="isTicket" style="width: 100%; display: flex; justify-content: center; ">
                    <div class="card-container container-bg" style="width: 70%">
                      <div
                        class=""
                        style="display: flex; gap: 28px; flex-direction: column;"
                      >
                        <div class="item-about-box" style="gap: 5px;">
                          <div class="title-text">
                            Coaching State <span id="time-left">( Hours left)</span>
                          </div>
                          <div>
                            <div style="width: 100%; display: inline-flex; justify-content: center;">
                              <div class="slider-container" style="">
                                <div class="slider-endpoint" style="left:0;"></div>
                                <div class="skills html" id="skillBar"></div>
                                <v-tooltip location="top">
                                  <template #activator="{ props }">
                                    <div v-bind="props" style="">
                                      <div class="slider-endpoint" style="right:0;"></div>
                                    </div>
                                  </template>
                                  <span style=" ">
                                    {{ openDate }}
                                  </span>
                                </v-tooltip>
                              </div>
                            </div>
                            <div style="display: inline-flex; justify-content: space-between; width: 100%">
                              <div class="title-text" style="text-transform: none; color: white; font-weight: 500; font-size: 12px;">
                                Apprentice
                              </div>
                              <div class="title-text" style="text-transform: none; color: white; font-weight: 500; font-size: 12px;">
                                Ready to onboard
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              <!-- </div> -->

            </div>
          </div>
          <div class="right-container">
            <div
              v-if="isLoading"
              style="margin: 50px; margin-top: 0px;"
            >
              <v-skeleton-loader 
                :type="'article'"
                :animation="true"
                theme="dark"
              />
            </div>
            <div v-else>
              <div class="card">
                <div class="card-body">
                  <div
                    class="row default-row"
                    style="padding-top: 0;"
                  >
                    <div class="col">
                      <div class="d-flex">
                        <div class="flex-shrink-0">
                          <img
                            src="../assets/class-users-default.png"
                            style="width: auto; height: 40px;padding-top: 4px;"
                            alt="Sample Image"
                          >
                        </div>
                        <div class="flex-grow-1 ms-3">
                          Owner 
                          <br>
                          <span class="badge bg-custom-secondary">
                            #{{ item.owner_address }}
                          </span>
                        </div>
                        <div v-if="isOwner">
                          <div class="dropdown">
                            <button
                              id="dropdownMenuButton1"
                              class="btn dropdown-toggle custom-dropdown-more-menu align-items-center"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <vue-feather type="more-vertical" />
                            </button>
                            <ul
                              class="dropdown-menu"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li
                                class="dropdown-item"
                                data-bs-toggle="modal"
                                data-bs-target="#transferPopup"
                                @click="showModal = true"
                              >
                                Transfer
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="isItemListed == true"
                    style="margin-bottom: 20px;"
                  >
                    <div class="text-solf-light">
                      Current Price
                    </div>
                    <div class="d-flex ">
                      <h4>{{ currentPriceCurrency }} {{ currentPrice.toFixed(2) }}</h4>
                    </div>
                  </div>
                    <div v-if="isOwner == false">
                      <div v-if="isItemListed == true">
                      <button
                        class="btn btn-primary d-flex justify-content-center align-items-center"
                        style="width: 100%; margin-bottom: 20px;"
                        data-bs-toggle="modal"
                        data-bs-target="#purchaseConfirmationModal"
                        @click="makeOffer"
                      >
                        <vue-feather
                          type="shopping-cart"
                          style="margin-right: 6px;"
                        /> Buy Now
                      </button>
                      <button v-if="item.itemCode !== 'STONE' && item.itemCode !== 'ARTIFACT'"
                        class="btn default-button d-flex justify-content-center align-items-center"
                        style="width: 100%;"
                        data-bs-toggle="modal"
                        data-bs-target="#offerPopup"
                        @click="makeOffer"
                      >
                        <vue-feather
                          type="tag"
                          style="margin-right: 6px;"
                        /> Make Offer
                      </button>
                    </div>
<!--<<<<<<< HEAD -->
                    </div>
                    <div v-else>
                      <div v-if="isListable">
                        <div v-if="isItemListed == true">
                          <button
                            class="btn default-button d-flex justify-content-center align-items-center"
                            style="width: 100%; background-color: darkred;"
                            @click="cancelListing"
                            :disabled="isCancelLoading"
                          >
                          <span v-if="isCancelLoading"><i class="fa fa-circle-o-notch fa-spin" /></span>
                          <span v-else> Cancel list </span>
                          </button>
                        </div>
                        <div v-else>
                          <button
                            class="btn default-button d-flex justify-content-center align-items-center"
                            style="width: 100%;"
                            data-bs-toggle="modal"
                            data-bs-target="#listForSalePopup"
                            @click="showModal = true"
                          >
                            List for sale
                          </button>
                        </div>
                        <br>
<!-- =======
                  </div>
                  <div v-else>
                    <div v-if="isListable">
                      <div v-if="isItemListed == true">
                        <button
                          class="btn default-button d-flex justify-content-center align-items-center"
                          style="width: 100%; background-color: darkred;"
                          @click="cancelListing"
                          :disabled="isCancelLoading"
                        >
                        <span v-if="isCancelLoading"><i class="fa fa-circle-o-notch fa-spin" /></span>
                        <span v-else> Cancel list </span>
                        </button>
>>>>>>> f2564a603ef9fb13372d39a4d51c45383b3b5468-->
                      </div>
                      <div v-else>
                        <button
                          class="btn default-button d-flex justify-content-center align-items-center"
                          style="width: 100%;"
                          data-bs-toggle="modal"
                          data-bs-target="#listForSalePopup"
                          @click="showModal = true"
                        >
                          List for sale
                        </button>
                      </div>
                      <br>
                    </div>

                  <div v-if="offerEvent.length > 0 && isItemListed == true && item.itemCode !== 'STONE' && item.itemCode !== 'ARTIFACT'" class="card product-card-container" style="margin-top: 20px; padding: 8px 0 18px 0;">
                    <div style="padding: 0px 10px 5px 20px; display: inline-flex; justify-content: space-between; align-items: center;">
                      <div> Offers </div>
                      <button 
                        v-if="isOwner"
                        class="btn btn-green"
                        @click="acceptOffer"
                        :disabled="isAcceptLoading"
                        style="width: 30%;"
                      >
                        <span v-if="isAcceptLoading"><i class="fa fa-circle-o-notch fa-spin" /></span>
                        <span v-else> Accept </span>
                      </button>
                    </div>
                    <div class="" style="border-bottom: 0.3px solid #A4A5A5; border-top: 0.3px solid #A4A5A5;">
                      <div class="row" style="padding: 0px 10px 0 20px; margin: 0; color: #A4A5A5; font-weight: 200; font-size: 14px;">
                        <div class="col" style="padding: 0px;">
                          JAB Price
                        </div>
                        <div class="col" style="padding: 0px;">
                          USD Price
                        </div>
                        <div class="col" style="padding: 0px;">
                          From
                        </div>
                      </div>
                    </div>
                    <div v-for="(x,index) in offerEvent" :key="index" class="row" style="padding: 5px 10px 0 20px; margin: 0; ">
                      <div class="col" style="padding: 0px;">
                        {{ x.offerPrice }}
                      </div>
                      <div class="col" style="padding: 0px;">
                        -
                      </div>
                      <div class="col" style="padding: 0px;">
                        {{ x.maker }}
                      </div>
                    </div>
                  </div> 

                </div>
              </div>
            </div>

            <div class="detail-box">
              <div
                class=""
                style="width: 100%;"
              >
                <h3 class="detail-title">
                  About
                </h3>
                <div class="card-container container-bg">
                  <div
                    class=""
                    style="display: flex; gap: 28px; flex-direction: column; width: 100%;"
                  >
                    <div v-if="item.rarity">
                      <div class="item-about-box">
                        <div class="title-text">
                          Rarity
                        </div>
                        <div>
                          <div
                            class="detail-text"
                            style="color: lightgray;"
                          >
                            <span
                              :class="['text-rarity-' + item.rarity]"
                              style="font-weight: 600;"
                            >
                              {{ item.rarity }}
                            </span>
                          </div>
                        </div>    
                      </div>
                    </div>

                    <div v-if="item.utilities">
                      <div class="item-about-box">
                        <div class="title-text">
                          Utilities
                        </div>
                        <div class="">
                          {{ item.utilities }}
                        </div>
                      </div>
                    </div>

                    <div class="item-about-box">
                      <div class="title-text">
                        DESCRIPTION
                      </div>
                      <div class="">
                        {{ item.description }}
                      </div>
                    </div>
                                        
                    <div v-if="item.tags">
                      <div
                        class="item-about-box"
                        style="width: 100%;"
                      >
                        <div class="title-text">
                          TAGS
                        </div>
                        <div style="display: flex; flex-direction: row; flex-wrap: wrap; gap: 5px; max-width: 100%;">
                          <div v-for="x in item.tags">
                            <a href="">
                              <div
                                class="detail-text"
                                style="color: lightgray;"
                              >
                                {{ x }}
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import Web3 from 'web3';
import { ref, onMounted, watch } from 'vue';
import axios from 'axios';
import { useStore } from '@/stores/index.ts';
import { shortenedWalletAddress, shortenedWalletAddress_rarible, shortenedWalletAddress_rarible2 } from '@/utils/walletUtils.js';

import { ethers } from 'ethers';
import { OpenSeaSDK, Chain, Seaport } from "opensea-js";


import LootboxABI from '@/contracts/LootboxABI.json';
import OpenTicketABI from '@/contracts/OpenTicketABI.json';
import {useToast} from 'vue-toast-notification';

import RecieveItemPopup from "@/components/popup/RecieveItemPopup.vue";
import OpeningItemPopup from "@/components/popup/OpeningItemPopup.vue";
import ListForSalePopup from "@/components/popup/ListForSalePopup.vue";
import TransferPopup from "@/components/popup/TransferPopup.vue";
import ConfirmPurchasePopup from "@/components/popup/ConfirmPurchasePopup.vue";
import OfferPopup from "@/components/popup/OfferPopup.vue";
import { getContractAddressByItemId } from '@/utils/contractUtils.js';
import { convertToUSD } from '@/utils/exchangeUtils.js';
import { useExchangeStore } from '@/stores/exchangeRate.ts';
import { timeAgo } from '@/utils/timeUtils.js';
import { kfServiceStore } from '@/stores/kfServiceStore.ts';

import FighterTicketERC721ABI from '@/contracts/FighterTicketERC721ABI.json';

import { createRaribleSdk } from "@rarible/sdk";
import { toItemId, toOrderId } from "@rarible/types";
import SuccessPopup from "@/components/popup/SuccessPopup.vue";

export default {
    components: {
        RecieveItemPopup,
        OpeningItemPopup,
        ListForSalePopup,
        TransferPopup,
        ConfirmPurchasePopup,
        OfferPopup,
        SuccessPopup
    },
    props: {
    },
    data() {
        return {
            isLoading: false,
            isOpening: false,
            isConfirmed: false,
            isRecieved: false,
            itemId : 0,
            tokenId : 0,
            orderHash : 0,
            item: {},
            isOwner: false,
            isItemListed: false,
            currentPrice: 0,
            currentPriceCurrency: 'MATIC',
            currentPriceCurrencyUsd: 0,
            walletAddress: '',
            recievedItem: {
                id: '',
                name: '',
                url: '',
                amount: 0,
            },
            isOpenable: false,
            isTicket: false,
            openDate: '',
            offerEvent: {},
            isListable: false,
            isCancelLoading: false,
            ownership: {}
        };
    },
    computed: {
        computedPaddingTop() {
            return this.isOpening ? '0' : '120px';
        },
        // isOpenable(){
        //   console.log("isOpenable" +this.checkTicketTime());
        //     return ((this.item.itemId == 4 || (this.item.itemId == 100 && this.checkTicketTime()) || this.item.itemId == 102) && this.isOwner && (this.item.amount == null || this.item.amount > 0)) ? true:false;
        // }
    },
    mounted() {
        this.fetchItemData();
      },
    methods: {
        async fetchEvent(collection) {
            try {
                const eventApiUrl = `https://testnet-api.rarible.org/v0.1/activities/byItem?type=&itemId=KROMA:${collection}:${this.$route.query.id}`;
                const eventResponse = await axios.get(eventApiUrl, {
                  headers: {
                      'accept': 'application/json',
                      'x-api-key': process.env.VITE_RARIBLE_API_KEY
                  }
                });

                // const index = eventResponse.data.activities.findIndex(item => item["@type"] === "LIST");
                // const filter = index !== -1 ? eventResponse.data.activities.slice(0, index) : eventResponse.data.activities;
                // console.error('Error fetching image: filter', filter);
                // const index2 = filter.findIndex(item => item["@type"] === "CANCEL_LIST" || item["@type"] === "SELL" || item["@type"] === "TRANSFER");
                // const filter2 = index2 !== -1 ? filter.slice(0, index2) : filter;
                // console.error('Error fetching image: filter2', filter2);

                // if(filter2.length > 0){
                //   this.isItemListed = true;
                //   // this.currentPrice = parseFloat(eventResponse.data.activities.find(f=>f["@type"] === "LIST").price);
                //   this.currentPriceCurrency = "JAB";
                // }
                // else{
                //   this.isItemListed = false;
                // }

                console.error('Error fetching image:', eventResponse);
                eventResponse.data.activities.sort((a, b) => {  
                    const date1 = new Date(b.lastUpdatedAt);
                    const date2 = new Date(a.lastUpdatedAt);
                    return date1 - date2;
                });
                // console.error('Error fetching image:', eventResponse);
                const transferIndex = eventResponse.data.activities.findIndex(item => item["@type"] === "LIST" || item["@type"] === "SELL" || item["@type"] === "TRANSFER");
                const filteredTransactions = transferIndex !== -1 ? eventResponse.data.activities.slice(0, transferIndex) : eventResponse.data.activities;
                console.error('Error fetching image:', filteredTransactions);
                const cancelBidHashes = new Set(
                  filteredTransactions
                    .filter(tx => tx["@type"] === "CANCEL_BID")
                    .map(tx => tx.hash)
                );

                const updatedTransactions = filteredTransactions.filter(tx => !cancelBidHashes.has(tx.hash));

                this.offerEvent = updatedTransactions
                .filter(event => event['@type'] == 'BID')
                .sort((a, b) => parseFloat(b.price) - parseFloat(a.price))
                .slice(0, 5).map(list => {          
                  return{
                    offerPrice: list.price,
                    usdPrice: 0,
                    maker: shortenedWalletAddress_rarible2(list.maker),
                  }
                });
            } catch (error) {
                console.error('Error fetching image:', error);
            }
        },
        async fetchItemData() {
            this.itemId = this.$route.query.itemId;
            this.tokenId = this.$route.query.id;
            this.isOwner = false;
            this.orderHash = this.$route.query.order;
            this.isListable = false;

            console.log("this.isOwner", this.tokenId);

            var collection = '';
            var itemResponse = {};

            if(this.itemId == 102){
                //Ticket 
                this.isLoading = true;
                const item = await axios.get(`https://s3.ap-southeast-1.amazonaws.com/metadata.kryptofighters.io/ticket/gen1_normal_ticket.json`);
                console.log(item);
                this.isLoading = false;
                this.item = {
                                token_id: item.data.item_id,
                                itemId: this.itemId,
                                description: item.data.lore,
                                image: item.data.image,
                                utilities: item.data.description,
                                name: item.data.name,
                                tags: item.data.tags,
                                itemCode: 'FIGHTER_TICKET',
                                owner_address: ''
                        };
            }else if(this.itemId == 4 || this.itemId == 1|| this.itemId == 2 || this.itemId == 3){
                //Fragment and Lootbox
                this.isLoading = true;
                const item = await axios.get(`https://s3.ap-southeast-1.amazonaws.com/metadata.kryptofighters.io/fragment/${this.$route.query.itemId}.json`);
                console.log(item);
                this.isLoading = false;
                this.item = {
                                token_id: this.tokenId,
                                itemId: this.itemId,
                                description: item.data.lore,
                                image: item.data.image,
                                utilities: item.data.description,
                                name: item.data.name,
                                tags: item.data.tags,
                                amount: this.$route.query.amount,
                                orderHash: this.orderHash,
                                itemCode: item.data.name,
                                owner_address: ''
                        };
                
            }else if(this.itemId == 100){
                collection = process.env.VITE_TICKET_CONTRACT_ADDRESS;
                this.isTicket = true;
                this.isListable = true;
                const contractAddress = getContractAddressByItemId(parseInt(this.itemId));

                this.isLoading = true;

                const apiUrl = `https://testnet-api.rarible.org/v0.1/items/KROMA:${contractAddress}:${this.tokenId}`;
                const headers = {
                  'accept': 'application/json',
                  'X-API-KEY': process.env.VITE_RARIBLE_API_KEY,
                };
                itemResponse = await axios.get(apiUrl, { headers });

                let effects = [];
                let rarity = null;
                if(this.itemId == 10){
                    effects.push("Auto battler enabled");
                    response.data.nft.traits.forEach((trait) => {
                        if (trait.trait_type === 'Rarity') {
                            rarity = trait.value;
                        }else{
                            effects.push(trait.trait_type);
                        }
                    });
                }
                
                this.isLoading = false;
                
                this.item = {
                            token_id: this.tokenId,
                            itemId: this.itemId,
                            description: itemResponse.data.meta.description,
                            image: itemResponse.data.meta.content[0].url,
                            name: itemResponse.data.meta.name,
                            effects: effects,
                            rarity: rarity,
                            itemCode: "FIGHTER_TICKET",
                            owner_address: '',
                            collection: collection
                        };
            }else if(this.itemId >= 10000){
                collection = (this.itemId >= 20000)? process.env.VITE_ARTIFACT_CONTRACT_ADDRESS:process.env.VITE_STONE_CONTRACT_ADDRESS;
                this.isTicket = false;
                this.isListable = true;
                this.isLoading = true;

                const filterType = (this.itemId >= 20000)? 'artifact':'stone';
                const nftResponse = await axios.get(`https://metadata.kryptofighters.io/item/${filterType}/${this.tokenId}.json`);
                
                this.item = {
                            token_id: this.tokenId,
                            itemId: this.itemId,
                            description: nftResponse.data.description,
                            image: nftResponse.data.image,
                            name: nftResponse.data.name,
                            rarity: nftResponse.data.properties.rarity,
                            itemCode: (this.itemId >= 20000)? "ARTIFACT":"STONE",
                            owner_address: '',
                            collection: collection
                        };

                this.isLoading = false;
                setTimeout(() => {
                  const itemImg = document.getElementById('item-image');
                  if (itemImg) {
                    itemImg.style.width = '80%';  // Example of setting a style
                  }
                });
                // this.fetchEvent(collection);
            }

            this.fetchEvent(collection);

            const ownerApiUrl = `https://testnet-api.rarible.org/v0.1/ownerships/byItem?itemId=KROMA:${collection}:${this.tokenId}`;
            const ownerResponse = await axios.get(ownerApiUrl, {
                headers: {
                    'accept': 'application/json',
                    'x-api-key': process.env.VITE_RARIBLE_API_KEY
                }
            });

            // console.log("ownerResponse", ownerResponse);
            if(this.$route.query.owned === 'true'){
              this.ownership = ownerResponse.data.ownerships.find(f=>f.owner === `ETHEREUM:${useStore().getWalletAddress.toLowerCase()}`);
              this.item.owner_address = shortenedWalletAddress_rarible2(this.ownership.owner);
            }
            else{
              if(this.itemId >= 10000){
                const apiUrl = `https://testnet-api.rarible.org/v0.1/items/KROMA:${collection}:${this.tokenId}`;
                const headers = {
                  'accept': 'application/json',
                  'X-API-KEY': process.env.VITE_RARIBLE_API_KEY,
                };
                itemResponse = await axios.get(apiUrl, { headers });
                console.log(itemResponse);
              }

              const sellOwner = itemResponse.data.bestSellOrder?.maker?? '';
              this.ownership = ownerResponse.data.ownerships.find(f=>f.owner === sellOwner);
            }            

            this.item.owner_address = shortenedWalletAddress_rarible2(this.ownership?.owner?? '');

            // console.log('owner ',this.ownership);

            if (useStore().getWalletAddress.localeCompare(shortenedWalletAddress_rarible(this.ownership?.owner?? '').toUpperCase(), undefined, { sensitivity: 'base' }) === 0) {
              this.isOwner = true;
            }

            if(this.ownership){
              if(this.ownership.bestSellOrder){
              this.isItemListed = true;
                this.currentPrice = parseFloat(this.ownership.bestSellOrder.makePrice);
                this.currentPriceCurrency = "JAB";
              }
              else{
                this.isItemListed = false;
              }
            }
            else{
              this.isItemListed = false;
            }

            if(this.item.itemId == 100){
              this.isOpenable = await this.checkTicketTime();
            }
            else{
              this.isOpenable = (this.item.itemId == 4 || this.item.itemId == 102) && this.isOwner && (this.item.amount == null || this.item.amount > 0);
            }
        },
        useItemClick() {
            console.log('useItemClick:' + this.itemId); // Add this line
            if(this.itemId == 4){
                this.openLootBox();
            }

            if(this.itemId == 100 || this.itemId == 102){
                this.openTicket();
            }

            // var $ = jQuery.noConflict();
            // $(document).ready(function() {
            //     $('#transferPopup').modal('show');
            // });
        },
        async openLootBox(){
            var openingAudio = document.getElementById('openingItemSFX');
            var recievedAudio = document.getElementById('recievedItemSFX');
            var $ = jQuery.noConflict();

            this.isOpening = true;
            this.isConfirmed = false;
            this.isRecieved = false;

            $(document).ready(function() {
                $('#openingItemPopup').modal('show');
            });
            openingAudio.play();

            try {
                const contractAddress =  process.env.VITE_LOOTBOX_CONTRACT_ADDRESS; 

                console.log(ethers);
                
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = await provider.getSigner();
                //const walletWithProvider = new ethers.Wallet(getRandomPrivateKey(config.walletPrivateKeys), provider);
                const contract = new ethers.Contract(contractAddress, LootboxABI, signer);
                console.log("contractAddress", contractAddress);
                const tx = await contract.open();
                console.log("tx", tx);

                this.isConfirmed = true;
                openingAudio.play();

                await tx.wait();

                console.log(tx);
                const receipt = await provider.getTransactionReceipt(tx.hash);

                if (receipt && receipt.logs) {
                    // Filter logs for the "LootBoxOpened" event
                    console.log("receipt.logs", receipt.logs);
                    //const eventFragment = contract.interface.getEvent("LootBoxOpened");

                   // const topicHash = keccak256("LootBoxOpened");
                    //console.log("topicHash", topicHash);
                    //console.log("eventFragment", eventFragment);
                    
                    const lootBoxOpenedLogs = receipt.logs.filter(log => {
                        try {
                            const parsedLog = contract.interface.parseLog(log);
                            return parsedLog.name === "LootBoxOpened";
                        } catch (error) {
                            return false;
                        }
                    });
                    
                    // Parse and access event data
                    lootBoxOpenedLogs.forEach(log => {
                        const eventData = contract.interface.parseLog(log);
                        console.log("LootBoxOpened:", eventData.args.user, eventData.args.rewards.toString());

                        this.getItemDetail(eventData.args.rewards.toString());
                    });

                    useToast().open({
                        message: 'Lootbox Opened',
                        position: 'top-right',
                        type: 'success',
                    });

                    if(this.item.amount != null){
                        this.item.amount--;
                    }

                } else {
                    console.log("No logs found in the transaction.");
                }

                this.isOpening = false;
                $(document).ready(function() {
                    $('#openingItemPopup').modal('hide');
                });

                this.isRecieved = true;
                $(document).ready(function() {
                    $('#recieveItemPopup').modal('show');
                });
                recievedAudio.play();
            } catch (error) {
                console.error("Error:", error);
                const errorMessage = error.message.length > 64 ? error.message.slice(0, 64) + '...' : error.message;
                useToast().open({
                    message: 'Error: ' + errorMessage,
                    position: 'top-right',
                    type: 'error',
                })
                
                this.isOpening = false;
                $(document).ready(function() {
                    $('#openingItemPopup').modal('hide');
                });
                    
               // this.item.image = saveImageUrl;
            }
        },
        async openTicket(){
            var openingAudio = document.getElementById('openingItemSFX');
            var recievedAudio = document.getElementById('recievedItemSFX');
            var $ = jQuery.noConflict();

            this.isOpening = true;
            this.isConfirmed = false;
            this.isRecieved = false;

            $(document).ready(function() {
                $('#openingItemPopup').modal('show');
            });
            openingAudio.play();

            const store = kfServiceStore();
            await store.fetchAccessToken();

            try {

                const contractAddress =  process.env.VITE_OPENTICKET_CONTRACT_ADDRESS; 
                console.log("contractAddress" + contractAddress);
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = await provider.getSigner();
                const contract = new ethers.Contract(contractAddress, OpenTicketABI, signer);
                
                console.log("this.tokenId", this.tokenId);
                const tx = await contract.openTicket(this.tokenId);
                
                console.log("tx", tx);

                this.isConfirmed = true;
                openingAudio.play();

                await tx.wait();

                console.log(tx);
                const receipt = await provider.getTransactionReceipt(tx.hash);

                console.log(receipt);

                // Check the logs array
                if (receipt && receipt.logs) {

                    const ticketOpenedLogs = receipt.logs.filter(log => {
                        try {
                            const parsedLog = contract.interface.parseLog(log);
                            return parsedLog.name === "TicketOpened";
                        } catch (error) {
                            return false;
                        }
                    });
                    
                    console.log("ticketOpenedLogs", ticketOpenedLogs);

                    var tokenId = null;
                    ticketOpenedLogs.forEach(log => {
                        const eventData = contract.interface.parseLog(log);
                        console.log("ticketOpened:", eventData.args.user, eventData.args.tokenId.toString());
                        tokenId = eventData.args.tokenId.toString();
                        //this.getItemDetail(eventData.args.rewards.toString());
                    });

                    if(tokenId!=null){
                      const requestBody = {
                         token_id: tokenId
                      };

                      const mintResponse = await axios.post(`https://reward-portal.kryptofighters.io/api/dojo/testnet/mint`, requestBody);
                      console.log("mintResponse", mintResponse);
                    
                  } else {
                    console.error('FighterMinted event not found in logs');
                  }
                } else {
                  console.error('No logs found in the transaction receipt');
                }


                
                    /*if(tokenId!=null){
                        const url = process.env.VITE_KF_SERVICE_BASE_URL + '/fighter/mint';
                        const data = {
                            tokenId: tokenId,
                            ticketType: 0,
                        };
                        const mintResponse = await axios.post(url, data, {
                                headers: {
                                    Authorization: `Bearer ${store.accessToken}`,
                                    'Content-Type': 'application/json',
                                },
                            });
                        this.recievedItem.id = 'FIGHTER';
                        this.recievedItem.name = 'Fighter';
                        this.recievedItem.url =  mintResponse.data.image;
                        this.recievedItem.amount = 1;
                        
                    }*/
                    

                    useToast().open({
                        message: 'Ticket Opened',
                        position: 'top-right',
                        type: 'success',
                    });

                    if(this.item.amount != null){
                        this.item.amount--;
                    }


                this.isOpening = false;
                $(document).ready(function() {
                    $('#openingItemPopup').modal('hide');
                });

                this.isRecieved = true;
                $(document).ready(function() {
                    $('#successPopup').modal('show');
                });
                recievedAudio.play();
            } catch (error) {
              var errorMessage = error.message.length > 64 ? error.message.slice(0, 64) + '...' : error.message;
              console.error("Error calling coachFighters:", error);
              if (error.data) {
                  console.error("Error details:", error.data);
                  errorMessage =  error.data;
              }

              if (error.reason) {
                  console.error("Error reason:", error.reason);
                  errorMessage = error.reason;
              }
                useToast().open({
                    message: 'Error:'+ errorMessage,
                    position: 'top-right',
                    type: 'error',
                })

                this.isOpening = false;
                $(document).ready(function() {
                    $('#openingItemPopup').modal('hide');
                });
        
                //this.item.image = saveImageUrl;
            }
        },
        getItemDetail(id){
            console.log(id);
            switch(id){
                case '1':   this.recievedItem.id = 'JAB';
                            this.recievedItem.name = 'JAB';
                            this.recievedItem.amount = 20;
                            break;
                case '2':   this.recievedItem.id = 'JAB';
                            this.recievedItem.name = 'JAB';
                            this.recievedItem.amount = 40;
                case '3':   this.recievedItem.id = 'JAB';
                            this.recievedItem.name = 'JAB';
                            this.recievedItem.amount = 80;
                            break;
                case '4':   this.recievedItem.id = 'JAB';
                            this.recievedItem.name = 'JAB';
                            this.recievedItem.amount = 200;                           
                            break;
                case '7':   this.recievedItem.id = 'ITM_FM_MCC';
                            this.recievedItem.name = 'Microchip';
                            this.recievedItem.amount = 1;
                            break;
                case '8':   this.recievedItem.id = 'ITM_FM_GLB';
                            this.recievedItem.name = 'Gold Bar';
                            this.recievedItem.amount = 1;
                            break;
                case '9':   this.recievedItem.id = 'ITM_FM_HLB';
                            this.recievedItem.name = 'Holo Bar';
                            this.recievedItem.amount = 1;
                            break;
                case '10':   this.recievedItem.id = 'NFT_TICKET';
                            this.recievedItem.name = 'NFT Ticket';
                            this.recievedItem.amount = 1;
                            break;
            }

            console.log(this.recievedItem);
        },
        async checkTicketTime(){
          try{
            const contractAddress = getContractAddressByItemId(parseInt(this.itemId));
            const provider = new ethers.providers.JsonRpcProvider(process.env.VITE_RPC_URL);
            const contract = new ethers.Contract(contractAddress, FighterTicketERC721ABI, provider);

            const metadata = await contract.getTokenMetadata(this.tokenId);
            // console.log("Metadata: 100", metadata);
            // console.log(`Created Date: ${new Date(metadata.created_date.toNumber() * 1000)}`);

            const now = new Date();
            const pastDate = new Date(metadata.created_date.toNumber() * 1000);

            const timeDifference = now - pastDate;
            const millisecondsInAnHour = 1000 * 60 * 60;
            const hoursDifference = timeDifference / millisecondsInAnHour;
            
            // console.log(`Created Date: ${timeDifference} , ${millisecondsInAnHour}`);

            let timeLeft = 24-Math.ceil(hoursDifference);
            if(timeLeft < 0){
              timeLeft = 0;
            }

            const timeLeftElement = document.getElementById('time-left');
            if (timeLeftElement) {
              timeLeftElement.innerHTML = `(${timeLeft} Hours left)`;  // Example of setting a style
            }

            const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // 24 hours * 60 minutes * 60 seconds * 1000 milliseconds
            this.openDate = new Date(pastDate.getTime() + oneDayInMilliseconds);
            const percent = (timeDifference / (millisecondsInAnHour*24))*100;

            const skillBar = document.getElementById('skillBar');
            if (skillBar) {
              skillBar.style.width = percent+'%';  // Example of setting a style
            }

            if(hoursDifference >= 24) {
              return true;
            } else {
              return false;
            }
          }
          catch (error) {
            console.error('Error fetching image:', error);
            return false;
          }
        },
        async cancelListing(){
          try{
            this.isCancelLoading = true;
            var tokenAddress = process.env.VITE_TICKET_CONTRACT_ADDRESS;
            if(this.item.itemCode == "STONE"){
              tokenAddress = process.env.VITE_STONE_CONTRACT_ADDRESS;
            }
            else if(this.item.itemCode == "ARTIFACT"){
              tokenAddress = process.env.VITE_ARTIFACT_CONTRACT_ADDRESS;
            }

            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = await provider.getSigner();
            const apiKey = process.env.VITE_RARIBLE_API_KEY; 
            const raribleSdk = createRaribleSdk(signer, "testnet", { apiKey });

            const item = await raribleSdk.apis.item.getItemById({ itemId: toItemId(`KROMA:${tokenAddress}:${this.tokenId}`) });
            console.log("bodyRequest:", item);
            const bodyRequest = {
              orderId: toOrderId(this.ownership.bestSellOrder.id)
            };

            console.log("bodyRequest:", bodyRequest);
            var order = await raribleSdk.order.cancel(bodyRequest);
            console.log("order:", order);
            var result = await order.wait()
            console.log("result:", result);

            useToast().open({
                            message: 'Successfully cancel.',
                            position: 'top-right',
                            type: 'success',
                        });
            this.isItemListed = false;
            this.isCancelLoading = false;
            this.checkTicketTime();
          }
          catch(error){
            console.error('Error cancelListing:', error);
            const errorMessage = error.message.length > 64 ? error.message.slice(0, 64) + '...' : error.message;
            useToast().open({
                            message: 'Error: ' + errorMessage,
                            position: 'top-right',
                            type: 'error',
                        });
            this.isCancelLoading = false;
          }
        },
        async acceptOffer(){
          try{
            this.isAcceptLoading = true;
            var tokenAddress = process.env.VITE_TICKET_CONTRACT_ADDRESS;
            if(this.item.itemCode == "STONE"){
              tokenAddress = process.env.VITE_STONE_CONTRACT_ADDRESS;
            }
            else{
              tokenAddress = process.env.VITE_ARTIFACT_CONTRACT_ADDRESS;
            }
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = await provider.getSigner();
            const apiKey = process.env.VITE_RARIBLE_API_KEY; 
            const raribleSdk = createRaribleSdk(signer, "testnet", { apiKey });

            const item = await raribleSdk.apis.item.getItemById({ itemId: toItemId(`KROMA:${tokenAddress}:${this.tokenId}`) });

            console.log("bodyRequest: item", item);
            const bodyRequest = {
              orderId: item.bestBidOrder.id,
              amount: 1,
            };

            // console.log("bodyRequest: item", item.bestBidOrder.id);

            // console.log("bodyRequest:", bodyRequest);
            var order = await raribleSdk.order.acceptBid(bodyRequest);
            // console.log("order:", order);
            var result = await order.wait()
            // console.log("result:", result);

            useToast().open({
                            message: 'Successfully accept offer.',
                            position: 'top-right',
                            type: 'success',
                        });

            this.isOwner = false;
            this.isItemListed = false;
            this.isAcceptLoading = false;
          }
          catch(error){
            console.error('Error acceptOffer:', error);
            const errorMessage = error.message.length > 64 ? error.message.slice(0, 64) + '...' : error.message;
            useToast().open({
                            message: 'Error: ' + errorMessage,
                            position: 'top-right',
                            type: 'error',
                        });
            this.isAcceptLoading = false;
          }
        },
    }
}
</script>
  
<style scoped>
.heading {
    color: white;
    font-size: 28px;
    font-weight: 600;
    line-height: 32px;
}

.item-placeholder {
    max-height: 400px;
    width: 300px;
    margin: auto;
    display: flex; 
    justify-content: center;
}

.item-image {
    max-height: 280px;
    width: auto;
}

.card-container {
    padding: 28px 32px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.container-bg {
    border: 1px solid #282c34;
    background-color: #171527;
    border-radius: 8px;
}

.title-text {
    text-transform: uppercase;
    color: #8e97a8;
    font-size: 11px;
    font-weight: 600;
}

.heading-3 {
    color: white;
    font-size: 28px;
    font-weight: 500;
    line-height: 32px;
    display: flex;
    align-items: center;
}

.sub-detail {
    padding: 4px 0 8px 8px;
    color: #8e97a8;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.detail-box {
    margin-top: 40px;
}

.detail-title {
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    color: white;
}
.item-about-box {
    display: grid;
    grid-gap: 4px;
    gap: 4px;
}

.detail-text {
    color: white;
    display: inline-flex;
    align-items: center;
    background-color: var(--light-background-color);
    border-radius: 8px;
    justify-content: center;
    padding: 4px 8px;
    font-size: 14px;
    line-height: 20px;
}

.item-detail {
    color: white;
    font-size: 14px;
    line-height: 20px;
}

.custom-list-item {
    margin-left: 0px;
  background-color: rgba(255, 255, 255, 0.0);
  /* Add any other styling you need */
}

.list-group .list-group-item {
   
  border-color: rgba(255, 255, 255, 0.0); /* Change the divider color */
}

.text-rarity-1, .text-rarity-Common {
    color: #B4B4B4; 
}
.text-rarity-2 {
    color: #29D403;
}
.text-rarity-Rare, .text-rarity-3 {
    color: #06D8DB;
}
.text-rarity-Epic {
    color: #B069FF; 
}
.text-rarity-Legendary {
    color: #DBC22D; 
}

.right-container {
    width: 40%; min-width: 320px; display: inline-block; vertical-align: top;
}

.custom-dropdown-more-menu::after {
  /* Hide the dropdown triangle icon */
  border: none !important;
  display: none;
}

.dropdown-menu {
    inset: 0px 0px auto auto !important;
}

.slider-container {
    position: relative;
    width:95%;
    display: flex;
    height: 4px;
    background-color: black;
}

.slider-endpoint {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    background: #ff65fa;
    border-radius: 50%;
}

.skills {
  text-align: right; /* Right-align text */
  color: black; /* White text color */
}

.html {width: 0%; background-color: #ff65fa;}

@media (max-width: 768px) {
    .left-container,
  .right-container {
    min-width: 100%;
    width: 100%; /* Take full width on smaller screens */
    margin-left: 0; /* Remove the margin between left and right containers */
  }
  .card-container {
  }
}

</style>
<!-- /style="width: 40%; min-width: 320px; display: inline-block; vertical-align: top; " -->