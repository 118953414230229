export const convertToUSD = (price, currency, ethToUsdExchangeRate, maticToUsdExchangeRate) => {
    let usdPrice;
    switch (currency) {
      case 'ETH':
        usdPrice = price * ethToUsdExchangeRate;
        break;
      case 'MATIC':
        usdPrice = price * maticToUsdExchangeRate;
        break;
      default:
        usdPrice = price; // Assume the currency is already in USD
    }
    return usdPrice;
  };